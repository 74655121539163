export function generateLinkIcon(type) {
    switch (type) {
        case 'package':
            return 'cilFolder';
        case 'scenario':
            return 'cilLayers';
        case 'Acknowledge':
            return 'cilTask';
        case 'Contracts':
            return 'cilDescription';
        case 'Request documents':
            return 'cilShareBoxed';
    }
}
