<template>
  <div
    class="clear-text-table"
    :class="{
      'clear-text-table--drop-zone': hasDropZone,
    }"
  >
    <h2 class="clear-text-table__heading">
      {{ heading }}
    </h2>

    <p v-if="subheading" class="clear-text-table__subtitle">
      {{ subheading }}
    </p>

    <img class="clear-text-table__image" src="@/assets/images/clear-text.jpg" />

    <CButton
      v-if="buttonText"
      class="clear-text-table__button"
      color="primary"
      @click="buttonHandler"
    >
      {{ buttonText }}
    </CButton>
  </div>
</template>

<script>
export default {
  name: 'ClearTextTable',
  props: {
    heading: {
      type: String,
      default: '',
    },
    subheading: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    hasDropZone: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['buttonHandler'],
  methods: {
    buttonHandler() {
      this.$emit('buttonHandler')
    },
  },
}
</script>

<style lang="scss" scoped>
.clear-text-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &--drop-zone {
    border: 3px dashed #677a89;
  }

  &__heading {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #1c262f;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 26px;
    color: #677a89;
  }

  &__image {
    width: 177px;
    height: 123px;
    object-fit: contain;
    object-position: center;
    mix-blend-mode: multiply;
  }

  &__button {
    margin-top: 15px;
  }
}
</style>
