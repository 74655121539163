<template>
  <div class="buttons-actions">
    <Star
      v-if="item.item_type === 'template' && type !== 'archive'"
      :active="item.is_favorite"
      class="buttons-actions__icon buttons-actions__icon--star"
      @click="addToQuickAccessHandler(item)"
    />

    <CButton
      v-if="mainActionButtonText"
      class="buttons-actions__button"
      color="primary"
      variant="outline"
      @click="mainActionHandler(item)"
    >
      {{ mainActionButtonText }}
    </CButton>

    <DropdownIconButton v-if="type !== 'archive'" :actions="actions" />
  </div>
</template>

<script>
import DropdownIconButton from '@/views/Templates/Components/DropdownIconButton'
import Star from '@/components/Star'

export default {
  name: 'ButtonsActions',
  components: {
    DropdownIconButton,
    Star,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['mainActionHandler', 'addToQuickAccessHandler'],
  computed: {
    mainActionButtonText() {
      const dictionary = {
        all: 'Add shortcut to',
        favorite: 'Add shortcut to',
        archive: 'Restore template',
        scenario: 'Move or copy',
        package: 'Move or copy',
        filter: '',
      }

      return dictionary[this.type]
    },
  },
  methods: {
    mainActionHandler(item) {
      this.$emit('mainActionHandler', { item, type: this.type })
    },
    addToQuickAccessHandler(item) {
      this.$emit('addToQuickAccessHandler', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons-actions {
  &__icon {
    margin-right: 10px;

    &--star {
      width: 16px;
      height: 16px;
    }
  }
  &__button {
    margin-right: 10px;
    white-space: nowrap;
  }
}
</style>
