<template>
    <SmartTable :key="files" class="files" :items="formattedFiles" :columns="columnsTable" selectable="selectable_id"
                :selected-items="selectedItems" infinity :sorter-value="sort" v-bind="attributes"
                @sorter-change="onSort" @event-drag-transfer="eventDrag" @event-drop-transfer="eventDrop"
                @event-drop-upload="eventDropUpload" @selected-items-change="updateSelectedItems"
                @additionalDropdownEvent="additionalDropdownEventHandler">
        <template #name="{ item }">
            <div v-if="type === 'archive'" class="files__link-button">
                <CIcon class="files__icon" :name="setIconNameByTemplateType(item.type)" size="sm"/>

                <Popper :content="item.name">
                    <span class="files__name"> {{ item.name }} </span>
                </Popper>

                <Popper v-if="item?.labels?.length" :content="joinLabelsName(item?.labels)">
                    <CIcon name="cilTags" class="files__icon files__icon--label"/>
                </Popper>
            </div>

            <router-link v-else-if="item.item_type === 'template'" class="files__link"
                         :to="{name: 'Template', params: { id:item.id }}">
                <CIcon class="files__icon" :name="setIconNameByTemplateType(item.type)" size="sm"/>

                <CIcon v-if="type === 'package' && item.is_main" name="cilCrown" class="files__icon"/>

                <Popper :content="item.name">
                    <span class="files__name"> {{ item.name }} </span>
                </Popper>

                <CBadge v-if="item.is_draft" class="files__draft" color="dark">Draft</CBadge>

                <Popper v-if="item?.labels?.length" :content="joinLabelsName(item?.labels)">
                    <CIcon name="cilTags" class="files__icon files__icon--label"/>
                </Popper>
            </router-link>

            <CButton v-else color="link" size="sm" class="files__link-button" @click="setActiveItem(item)">
                <CIcon class="files__icon" :name="setIconNameByTemplateType(item.item_type)" size="sm"/>

                <Popper :content="item.name">
                    <span class="files__name"> {{ item.name }} </span>
                </Popper>
            </CButton>
        </template>

        <template #button="{ item }">
            <ButtonsActions class="files__actions" :item="item" :type="type" :actions="action(item)"
                            @mainActionHandler="mainActionHandler" @addToQuickAccessHandler="addToQuickAccessHandler"/>
        </template>

        <template #dd_clear_text="{ item }">
            <ClearTextTable heading="There are no templates for this package yet!"
                            subheading="But we are almost there! Let’s add the new template to this package"
                            button-text="Add templates" has-drop-zone
                            @button-handler="additionalDropdownEventHandler(item.additionalAction.buttonEvent)"/>
        </template>

        <template v-if="type === 'all'" #clear_text>
            <ClearTextTable heading="There are no templates yet!"
                            subheading="But we are almost there! Let's upload new templates"
                            button-text="Create Task/Contract Template" @button-handler="createTemplate"/>
        </template>

        <template v-else-if="type === 'archive'" #clear_text>
            <ClearTextTable heading="No archived templates"/>
        </template>

        <template v-else-if="type === 'favorite'" #clear_text>
            <ClearTextTable heading="No favorites templates"/>
        </template>

        <template v-else-if="type === 'filter'" #clear_text>
            <ClearTextTable heading="No results found"/>
        </template>

        <template v-else #clear_text>
            <ClearTextTable heading="There are no packages yet!"
                            subheading="But we are almost there! Let's create packages"
                            button-text="Create package" @button-handler="createPackage"/>
        </template>
    </SmartTable>
</template>

<script>
import {generateLinkIcon} from '@/views/Templates/Components/Table/generatorLinkByTemplateType';
import ButtonsActions from '@/views/Templates/Components/Table/ButtonsActions';
import ClearTextTable from '@/views/Templates/Components/Table/ClearTextTable';

export default {
    components: {
        ClearTextTable,
        ButtonsActions,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        selectedItems: {
            type: Array,
            default: () => [],
        },
        files: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: '',
        },
        activeScenario: {
            type: Object,
            default: () => ({}),
        },
        sort: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: [
        'eventDrag',
        'eventDrop',
        'addShortcutTo',
        'addToQuickAccess',
        'update:selectedItems',
        'viewDetails',
        'archive',
        'delete',
        'download',
        'moveOrCopy',
        'setPrimaryTemplate',
        'restoreTemplate',
        'setActiveScenario',
        'openAddTemplatesPopup',
        'sort',
        'eventDropUpload',
        'createTemplate',
        'createPackage',
    ],
    data() {
        return {
            columnsTable: [
                {
                    key: 'name',
                    label: 'Name',
                },
                {
                    key: 'updated_at',
                    label: 'Date Modified',
                    format: (value) => (value ? this.$DwT(value) : ''),
                },
                {
                    key: 'modified_by',
                    label: 'Modified By',
                },
            ],
        };
    },
    computed: {
        attributes() {
            return this.type === 'package' && !this.loading ? {dropdown: true, draggable: true} : {};
        },
        formattedFiles() {
            return this.files;
        },
    },
    methods: {
        onSort(sort) {
            this.$emit('sort', sort);
        },
        action(item) {
            const setting = {
                details: {
                    label: 'View details',
                    icon: 'cilInfo',
                    event: () => this.viewDetailsHandler(item.id),
                },
                archive: {
                    label: 'Archive',
                    icon: 'cilTrash',
                    event: () => this.archiveHandler(item),
                },
                delete: {
                    label: 'Delete',
                    icon: 'cilTrash',
                    event: () => this.deleteHandler(item),
                },
                download: {
                    label: 'Download',
                    icon: 'cilCloudDownload',
                    event: () =>
                        this.downloadHandler({
                            id: item.id,
                            type: item.item_type,
                        }),
                },
                move_copy: {
                    label: 'Move or copy',
                    icon: 'cilTrash',
                    event: () => this.moveOrCopyHandler(item),
                },
                shortcut: {
                    label: 'Add shortcut to',
                    icon: 'cilCursorMove',
                    event: () => this.addShortcutToHandler(item),
                },
                set_primary: {
                    label: item.is_main ? 'Deselect as Primary' : 'Select as Primary',
                    icon: 'cilCrown',
                    event: () => this.setPrimaryTemplateHandler(item.id),
                },
            };

            const dictionary = {
                all: {
                    template: ['download', 'archive', 'details'],
                },
                favorite: {
                    template: ['download', 'archive', 'details'],
                },
                archive: {
                    template: [],
                },
                scenario: {
                    package: ['download', 'delete'],
                    template: ['download', 'shortcut', 'delete', 'details'],
                },
                package: {
                    template: [
                        'set_primary',
                        'download',
                        'shortcut',
                        'delete',
                        'details',
                    ],
                },
                filter: {
                    scenario: ['download', 'delete'],
                    package: ['download', 'delete'],
                    template: [
                        'download',
                        'shortcut',
                        'archive',
                        'details',
                    ],
                },
            };

            if (item.file_type === 'secondary') {
                dictionary.package.template = dictionary.package.template.filter((el) => el !== 'set_primary');
            }

            const itemType = item.item_type;
            
            return dictionary[this.type][itemType]?.map((item) => setting[item]);
        },

        joinLabelsName(labels) {
            return labels?.map((el) => el.label)?.join(', ');
        },

        setIconNameByTemplateType(type) {
            return generateLinkIcon(type);
        },
        setActiveItem(item) {
            const data = {};

            switch (item.item_type) {
                case 'scenario':
                    data.scenarioId = item.id;
                    break;
                case 'package':
                    data.packageId = item.id;
                    data.scenarioId = item.scenario_id;
                    break;
            }

            this.$emit('setActiveScenario', data);
        },
        updateSelectedItems(items) {
            this.$emit('update:selectedItems', items);
        },
        eventDrag(data) {
            this.$emit('eventDrag', data);
        },
        eventDrop(data) {
            this.$emit('eventDrop', data);
        },
        eventDropUpload(data) {
            this.$emit('eventDropUpload', data);
        },
        createTemplate() {
            this.$emit('createTemplate');
        },
        createPackage() {
            this.$emit('createPackage');
        },
        addToQuickAccessHandler(data) {
            this.$emit('addToQuickAccess', data);
        },
        mainActionHandler(data) {
            switch (data.type) {
                case 'archive':
                    this.restoreTemplateHandler(data.item.id);
                    break;
                case 'all':
                    this.addShortcutToHandler(data.item);
                    break;
                case 'favorite':
                    this.addShortcutToHandler(data.item);
                    break;
                default:
                    this.moveOrCopyHandler(data.item);
                    break;
            }
        },
        viewDetailsHandler(data) {
            this.$emit('viewDetails', data);
        },
        archiveHandler(data) {
            this.$emit('archive', [data.id], data.name);
        },
        dataAdapter(data) {
            if (this.activeScenario?.packageId && this.activeScenario?.scenarioId) {
                return {
                    item_type: data.item_type,
                    from: 'package',
                    from_id: this.activeScenario.packageId,
                    [`${data.item_type}s`]: [+data.selectable_id?.split(',')[0]],
                };
            } else if (this.activeScenario?.scenarioId) {
                return {
                    item_type: data.item_type,
                    from: 'scenario',
                    from_id: this.activeScenario.scenarioId,
                    [`${data.item_type}s`]: [data.id],
                };
            } else {
                const _data = {
                    item_type: data.item_type,
                    [`${data.item_type}s`]: [data.id],
                };

                if (data.item_type === 'package') {
                    _data.from = 'scenario';
                    _data.from_id = data.scenario_id;
                }

                return _data;
            }
        },
        deleteHandler(data) {
            this.$emit('delete', {...this.dataAdapter(data), name: data.name});
        },
        downloadHandler(data) {
            this.$emit('download', data);
        },
        moveOrCopyHandler(data) {
            this.$emit('moveOrCopy', {...this.dataAdapter(data), name: data.name});
        },
        addShortcutToHandler(data) {
            this.$emit('addShortcutTo', {items: [data.id], name: data.name});
        },
        setPrimaryTemplateHandler(templateId) {
            this.$emit('setPrimaryTemplate', {
                templateId,
                packageId: this.activeScenario?.packageId,
            });
        },
        restoreTemplateHandler(id) {
            this.$emit('restoreTemplate', [id]);
        },
        additionalDropdownEventHandler(name) {
            const config = {
                uploadPrimaryContracts: {
                    title: 'Add primary package templates',
                    type: 'primary',
                    packageId: this.activeScenario?.packageId,
                },
                uploadSecondaryContracts: {
                    title: 'Add flowdown templates',
                    type: 'secondary',
                    packageId: this.activeScenario?.packageId,
                },
            };

            this.$emit('openAddTemplatesPopup', config[name] || {});
        },
    },
};
</script>

<style lang="scss" scoped>
.files {
    &__actions {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    &__link {
        display: flex;
        align-items: center;
    }

    &__name {
        display: block;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    &__draft {
        font-size: 10px;
        border-radius: 5px;
        margin-left: 8px;
    }

    &__icon {
        margin-right: 10px;

        &--label {
            margin-left: 10px;
            margin-right: 0;
        }

        &--star {
            width: 16px;
            height: 16px;
        }
    }

    &__link-button {
        padding: 0;
        color: #0068ad;
        display: flex;
        align-items: center;
    }

    &__button {
        margin-right: 10px;
    }
}
</style>
