<template>
  <CDropdown class="bulk-action" color="primary">
    <CDropdownToggle
      :key="items"
      color="primary"
      variant="outline"
      :disabled="loading || !items.length"
    >
      Bulk Action
    </CDropdownToggle>
    <CDropdownMenu>
      <CDropdownItem
        v-for="item in actionsList"
        :key="`${item.label}-${type}`"
        @click="item.event()"
      >
        {{ item.label }}
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import Gate from '@/services/Gate/Gate'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'BulkAction',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activeScenario: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'moveOrCopy',
    'restoreTemplates',
    'delete',
    'archive',
    'shortcut',
    'download',
  ],
  computed: {
    actionsList() {
      const setting = {
        download: {
          label: 'Download',
          event: this.downloadHandler,
        },
        shortcut: {
          label: 'Add shortcut to',
          event: this.shortcutHandler,
        },
        archive: {
          label: 'Archive',
          event: this.archiveHandler,
        },
        move_copy: {
          label: 'Move or copy',
          event: this.moveOrCopyHandler,
        },
        delete: {
          label: 'Delete',
          event: this.deleteHandler,
        },
        return_template: {
          label: 'Restore templates',
          event: this.restoreTemplatesHandler,
        },
      }

      const dictionary = {
        all: ['download', 'shortcut', 'archive'],
        favorite: ['download', 'shortcut', 'archive'],
        archive: ['return_template'],
        scenario: ['download', 'move_copy', 'delete'],
        package: ['download', 'move_copy', 'delete'],
        filter: ['download'],
      }

      return this.type && dictionary[this.type].map((item) => setting[item])
    },
  },
  methods: {
    parsingItems() {
      return this.items?.reduce((acc, current) => {
        const el = current.split(',')
        const elType = `${el[1]}s`
        const elId = +el[0]

        if (acc[elType]) {
          acc[elType] = [elId, ...acc[elType]]
        } else {
          acc[elType] = [elId]
        }

        return acc
      }, {})
    },
    hasOnlyTemplates(items) {
      return Object.keys(items).every((el) => el === 'templates')
    },
    async moveOrCopyHandler() {
      const check = await Gate.can('edit', 'template')
      if (!check) {
        this.$notify(permissionError)
        return false
      }
      const items = this.parsingItems()
      const hasOnlyTemplates = this.hasOnlyTemplates(items)
      const data = {
        ...items,
      }

      if (!this.activeScenario?.scenarioId) return

      if (hasOnlyTemplates) {
        data.item_type = 'template'
      }

      if (this.activeScenario?.packageId) {
        data.from = 'package'
        data.from_id = this.activeScenario?.packageId
      } else {
        data.from = 'scenario'
        data.from_id = this.activeScenario?.scenarioId
      }

      this.$emit('moveOrCopy', { ...data })
    },
    async restoreTemplatesHandler() {
      const check = await Gate.can('edit', 'template')
      if (!check) {
        this.$notify(permissionError)
        return false
      }
      const items = this.parsingItems()
      this.$emit('restoreTemplates', items?.templates || [])
    },
    async deleteHandler() {
      const check = await Gate.can('delete', 'template')
      if (!check) {
        this.$notify(permissionError)
        return false
      }
      const items = this.parsingItems()
      if (this.activeScenario.packageId) {
        this.$emit('delete', {
          from: 'package',
          from_id: this.activeScenario.packageId,
          ...items,
        })
      } else {
        this.$emit('delete', {
          from: 'scenario',
          from_id: this.activeScenario.scenarioId,
          ...items,
        })
      }
    },
    async archiveHandler() {
      const check = await Gate.can('delete', 'template')
      if (!check) {
        this.$notify(permissionError)
        return false
      }
      const items = this.parsingItems()
      this.$emit('archive', items?.templates || [])
    },
    async shortcutHandler() {
      const check = await Gate.can('edit', 'template')
      if (!check) {
        this.$notify(permissionError)
        return false
      }
      const items = this.parsingItems()
      this.$emit('shortcut', { items: items.templates || [] })
    },
    downloadHandler() {
      let items = this.parsingItems()

      if (items?.templates?.length) {
        items = {
          ...items,
          templates: items?.templates?.map((el) => Number.parseInt(el)),
        }
      }

      this.$emit('download', items)
    },
  },
}
</script>

<style lang="scss" scoped>
.bulk-action {
}
</style>
