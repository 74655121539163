<template>
  <div class="scenarios">
    <ul class="scenarios__list">
      <li
        v-for="scenario in scenarios"
        :key="scenario.id"
        class="scenarios__list-item"
      >
        <div
          :id="scenario.id"
          class="scenarios__item"
          :class="{
            'scenarios__item--active':
              activeScenario?.scenarioId === scenario.id,
            'scenarios__item--drag-over': dragOverId === scenario.id,
          }"
          @click="setActiveScenario(scenario.id)"
          @dblclick="openScenario(scenario.id)"
          @drop="eventDrop"
          @dragenter.prevent="dragOverHandler(scenario.id)"
          @dragover.prevent="dragOverHandler(scenario.id)"
          @dragleave="dragLeaveHandler"
        >
          <CIcon
            class="scenarios__icon"
            :class="{
              'scenarios__icon--rotate': opened.includes(scenario.id),
            }"
            name="cilArRight"
            size="sm"
            @click.stop="openScenario(scenario.id)"
          />

          <CIcon class="scenarios__icon" name="cilLayers" />

          <Popper :content="scenario.name">
            <span class="scenarios__label">{{ scenario.name }}</span>
          </Popper>

          <DropdownIconButton
            class="scenarios__actions"
            :actions="scenarioActions(scenario)"
          />
        </div>

        <ul v-if="opened.includes(scenario.id)" class="scenarios__inner-list">
          <template v-if="scenario.packages.length">
            <li
              v-for="packageData in scenario.packages"
              :key="packageData.id"
              class="scenarios__list-item"
            >
              <div
                :id="packageData.id"
                class="scenarios__item scenarios__item--inner"
                :class="{
                  'scenarios__item--active':
                    activeScenario?.packageId === packageData.id,
                  'scenarios__item--drag-over': dragOverId === packageData.id,
                }"
                @click="setActiveScenario(scenario.id, packageData.id)"
                @drop="eventDrop"
                @dragenter.prevent="dragOverHandler(packageData.id)"
                @dragover.prevent="dragOverHandler(packageData.id)"
                @dragleave="dragLeaveHandler"
              >
                <CIcon class="scenarios__icon" name="cilFolder" />

                <Popper :content="packageData.name">
                  <span class="scenarios__label">{{ packageData.name }}</span>
                </Popper>

                <DropdownIconButton
                  class="scenarios__actions"
                  :actions="packageActions(scenario, packageData)"
                />
              </div>
            </li>
          </template>

          <li v-else class="scenarios__list-item">
            <div
              class="scenarios__item scenarios__item--inner scenarios__item--disabled"
            >
              <Popper content="The scenario is empty">
                <span class="scenarios__label">The scenario is empty</span>
              </Popper>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import DropdownIconButton from '@/views/Templates/Components/DropdownIconButton'
export default {
  name: 'Scenarios',
  components: { DropdownIconButton },
  props: {
    scenarios: {
      type: Array,
      default: () => [],
    },
    activeScenario: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'change',
    'event-drop',
    'createPackage',
    'delete',
    'download',
    'rename',
    'moveOrCopy',
    'duplicateScenario',
  ],
  data() {
    return {
      opened: [],
      dragOverId: null,
    }
  },
  watch: {
    activeScenario(data) {
      if (data?.packageId && !this.opened.includes(data.scenarioId)) {
        this.openScenario(data.scenarioId)
      }
    },
  },
  methods: {
    scenarioActions(scenarioData) {
      return [
        {
          label: 'Create new package',
          icon: 'cilFolder',
          event: () => this.createPackage(scenarioData.id),
        },
        {
          label: 'Duplicate scenario',
          icon: 'cilCopy',
          event: () => this.duplicateScenario('scenario', scenarioData.id),
        },
        {
          label: 'Rename scenario',
          icon: 'cilPencil',
          event: () =>
            this.renameHandler('scenario', {
              id: scenarioData.id,
              name: scenarioData.name,
            }),
        },
        {
          label: 'Download scenario',
          icon: 'cilDataTransferDown',
          event: () =>
            this.downloadHandler({
              type: 'scenario',
              id: scenarioData.id,
            }),
        },
        {
          label: 'Delete scenario',
          icon: 'cilTrash',
          event: () =>
            this.deleteHandler(
              {
                scenarios: [scenarioData.id],
              },
              scenarioData.name,
            ),
        },
      ]
    },
    packageActions(scenarioData, packageData) {
      return [
        {
          label: 'Rename package',
          icon: 'cilPencil',
          event: () =>
            this.renameHandler('package', {
              scenario_id: scenarioData.id,
              id: packageData.id,
              name: packageData.name,
            }),
        },
        {
          label: 'Download package',
          icon: 'cilDataTransferDown',
          event: () =>
            this.downloadHandler({
              type: 'package',
              id: packageData.id,
            }),
        },
        {
          label: 'Move or copy',
          icon: 'cilCursorMove',
          event: () =>
            this.moveOrCopy({
              from: 'scenario',
              from_id: scenarioData.id,
              item_type: 'package',
              packages: [packageData.id],
              name: packageData.name,
            }),
        },
        {
          label: 'Delete package',
          icon: 'cilTrash',
          event: () =>
            this.deleteHandler(
              {
                from: 'scenario',
                from_id: scenarioData.id,
                packages: [packageData.id],
              },
              packageData.name,
            ),
        },
      ]
    },

    setActiveScenario(scenarioId, packageId) {
      this.$emit('change', { scenarioId, packageId })
    },
    openScenario(id) {
      this.opened = this.opened.includes(id)
        ? this.opened.filter((scenario) => scenario !== id)
        : [...this.opened, id]
    },

    eventDrop(event) {
      this.dragOverId = null
      this.$emit('event-drop', event)
    },
    dragOverHandler(id) {
      this.dragOverId = id
    },
    dragLeaveHandler() {
      this.dragOverId = null
    },

    createPackage(scenarioId) {
      this.$emit('createPackage', scenarioId)
    },
    duplicateScenario(from, id) {
      this.$emit('duplicateScenario', { from, id })
    },
    deleteHandler(data, name) {
      this.$emit('delete', data, name)
    },
    downloadHandler(data) {
      this.$emit('download', data)
    },
    renameHandler(key, data) {
      this.$emit('rename', { key, ...data })
    },
    moveOrCopy(data) {
      this.$emit('moveOrCopy', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.scenarios {
  padding: 0 16px;

  &__list,
  &__inner-list {
    list-style: none;
    padding: 0;
  }

  &__list {
    margin: 0 -16px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    cursor: pointer;
    width: 100%;
    user-select: none;

    &--inner {
      padding: 6px 16px 6px 40px;
    }

    &:hover {
      background-color: rgba(238, 238, 239, 0.7);
    }

    &--active {
      background-color: #eeeeef;
    }

    &--drag-over {
      background-color: #677a89;
      color: #ffffff;
    }

    &--disabled {
      opacity: 0.5;
      cursor: default;

      &:hover {
        background-color: transparent;
      }
    }
  }

  &__label {
    display: block;
    max-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__icon {
    margin-right: 8px;

    &--rotate {
      transform: rotate(90deg);
    }
  }

  &__actions {
    margin-left: auto;
  }
}
</style>
