<template>
  <CModal
    content-class-name="create-new-package"
    size="lg"
    backdrop="static"
    :visible="visible"
    @close="closePopup"
  >
    <CModalHeader class="create-new-package__header" :close-button="false">
      <CModalTitle class="create-new-package__heading">New Package</CModalTitle>
    </CModalHeader>

    <CModalBody class="create-new-package__body">
      <CNSelect
        v-if="scenarios.length"
        v-model="selectedScenario"
        class="create-new-package__item"
        :options="scenarios"
        label="Scenario"
        required
        :disabled="loading"
        :error="validationErrors['selectedScenario']"
        :invalid="!!validationErrors['selectedScenario']"
        @blur="validateField('selectedScenario')"
      />

      <CNInput
        v-model="name"
        class="create-new-package__item"
        required
        label="Package Name"
        :disabled="loading"
        :error="validationErrors['name']"
        :invalid="!!validationErrors['name']"
        @blur="validateField('name')"
      />
    </CModalBody>

    <CModalFooter class="create-new-package__footer">
      <CButton
        class="create-new-package__button"
        variant="outline"
        color="primary"
        :disabled="loading"
        @click="closePopup"
      >
        Cancel
      </CButton>
      <CButton
        class="create-new-package__button"
        color="primary"
        :disabled="loading"
        @click="createPackage"
      >
        Create
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import rules from '@/utils/validator/rules'
import validator from '@/utils/validator'
import { mapActions } from 'vuex'
import { parseErrorFromResponse } from '@/utils/helper'

export default {
  name: 'CreateNewPackage',
  inject: ['toast'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     * data: {
     *   selectedScenario: Number,
     *   list: Array
     * }
     */
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'updateData'],
  data() {
    return {
      name: '',
      scenarios: [],
      selectedScenario: this.data?.selectedScenario || null,
      validator: {},
      validationErrors: {},

      loading: false,
    }
  },
  watch: {
    visible(visible) {
      if (!visible) {
        this.clearData()
      } else {
        this.setDefaultData()
        this.setRules()
      }
    },
  },
  methods: {
    ...mapActions({
      createNewPackage: 'createNewPackage',
    }),

    closePopup() {
      this.$emit('close')
    },
    setDefaultData() {
      this.selectedScenario = this.data?.selectedScenario || null
      this.scenarios = this.data?.list?.length
        ? this.data.list.map((el) => ({ value: el.id, name: el.name }))
        : []
    },
    setRules() {
      this.validator = validator({
        name: [rules.required, rules.strMax(255)],
        selectedScenario: [rules.required],
      })
    },
    validateField(key) {
      this.validationErrors.name = this.validator.validate(key, this[key])
    },
    clearData() {
      this.name = ''
      this.selectedScenario = null
      this.validator = {}
      this.validationErrors = {}
    },
    createPackage() {
      this.setRules()
      const validationResult = this.validator.validateAll({
        name: this.name,
        selectedScenario: this.selectedScenario,
      })
      this.validationErrors = this.$deepClone(validationResult.validationErrors)

      if (validationResult.hasErrors) return

      this.loading = true
      this.createNewPackage({
        name: this.name,
        collection_id: this.selectedScenario,
      })
        .then((response) => {
          this.closePopup()
          this.toast('success', response)
          this.$emit('updateData')
        })
        .catch((error) => {
          const errorsObject = error.response.data?.errors
          const errors = parseErrorFromResponse(errorsObject)

          if (errorsObject && errors) {
            this.validationErrors = {
              ...this.validationErrors,
              ...errors,
            }
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss">
.create-new-package {
  max-width: 560px;
  padding: 8px;

  &__header {
    justify-content: center;
  }

  &__heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__footer {
    justify-content: center;
  }

  &__button {
    min-width: 140px;
  }
}
</style>
