<template>
  <div class="directories">
    <ul class="directories__list">
      <li
        v-for="directory in directories"
        :key="directory.value"
        class="directories__list-item"
        :class="{
          'directories__list-item--active': activeDirectory === directory.value,
        }"
        @click="changeDirectory(directory.value)"
      >
        <CIcon class="directories__icon" :name="directory.icon" />

        <span class="directories__label">{{ directory.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Directories',
  props: {
    activeDirectory: {
      type: String,
      default: '',
    },
    directories: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change'],
  methods: {
    changeDirectory(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.directories {
  padding: 0 16px;

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 -16px;
  }

  &__list-item {
    padding: 6px 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;

    &:hover {
      background-color: rgba(238, 238, 239, 0.7);
    }

    &--active {
      background-color: #eeeeef;
    }
  }

  &__icon {
    margin-right: 8px;
  }
}
</style>
