<template>
  <div class="dropdown-icon-button">
    <CPopover placement="right" :trigger="['focus']">
      <template #toggler="{ on }">
        <button
          class="dropdown-icon-button__button"
          type="button"
          v-on="on"
          @click.stop
          @dblclick.prevent.stop
        >
          <CIcon :name="icon" />
        </button>
      </template>

      <template #content>
        <CListGroup class="dropdown-icon-button__content">
          <CListGroupItem
            v-for="action in actions"
            :key="action.label"
            :disabled="action.disabled"
            component="button"
            class="dropdown-icon-button__item"
            @click="action.event()"
          >
            <CIcon
              class="dropdown-icon-button__icon"
              :name="action.icon"
              size="sm"
            />

            <span>{{ action.label }}</span>
          </CListGroupItem>
        </CListGroup>
      </template>
    </CPopover>
  </div>
</template>

<script>
export default {
  name: 'DropdownIconButton',
  props: {
    icon: {
      type: String,
      default: 'cilOptions',
    },
    /**
     * action: {
     *   label: String,
     *   event: Function,
     *   icon: String,
     *   disabled: Boolean
     * }
     */
    actions: {
      type: Array,
      default: () => [],
      required: true,
    },
  }
}
</script>

<style lang="scss">
.dropdown-icon-button {
  &__button {
    background: transparent;
    border: none;
    padding: 0 6px;
    margin: 0;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 8px;
  }

  &__content {
    margin: -1rem;
  }
}
</style>
