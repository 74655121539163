<template>
  <CModal
    content-class-name="create-new-scenario"
    size="lg"
    backdrop="static"
    :visible="visible"
    @close="closePopup"
  >
    <CModalHeader class="create-new-scenario__header" :close-button="false">
      <CModalTitle class="create-new-scenario__heading"
        >New Scenario</CModalTitle
      >
    </CModalHeader>

    <CModalBody class="create-new-scenario__body">
      <CNInput
        v-model="name"
        required
        label="Scenario Name"
        :error="validationErrors['name']"
        :invalid="!!validationErrors['name']"
        :disabled="loading"
        @blur="validateField"
      />
    </CModalBody>

    <CModalFooter class="create-new-scenario__footer">
      <CButton
        class="create-new-scenario__button"
        variant="outline"
        color="primary"
        :disabled="loading"
        @click="closePopup"
      >
        Cancel
      </CButton>
      <CButton
        class="create-new-scenario__button"
        color="primary"
        :disabled="loading"
        @click="createScenario"
      >
        Create
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import rules from '@/utils/validator/rules'
import validator from '@/utils/validator'
import { mapActions } from 'vuex'
import { parseErrorFromResponse } from '@/utils/helper'

export default {
  name: 'CreateNewScenario',
  inject: ['toast'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'updateData'],
  data() {
    return {
      name: '',
      validator: {},
      validationErrors: {},

      loading: false,
    }
  },
  watch: {
    visible(visible) {
      if (!visible) {
        this.clearData()
      } else {
        this.setRules()
      }
    },
  },
  methods: {
    ...mapActions({
      createNewScenario: 'createNewScenario',
    }),

    closePopup() {
      this.$emit('close')
    },
    setRules() {
      this.validator = validator({
        name: [rules.required, rules.strMax(255)],
      })
    },
    validateField() {
      this.validationErrors.name = this.validator.validate('name', this.name)
    },
    clearData() {
      this.name = ''
      this.validator = {}
      this.validationErrors = {}
    },
    createScenario() {
      this.setRules()
      const validationResult = this.validator.validateAll({ name: this.name })
      this.validationErrors = this.$deepClone(validationResult.validationErrors)

      if (validationResult.hasErrors) return

      this.loading = true
      this.createNewScenario({ name: this.name })
        .then((response) => {
          this.closePopup()
          this.toast('success', response)
          this.$emit('updateData')
        })
        .catch((error) => {
          const errorsObject = error.response.data?.errors
          const errors = parseErrorFromResponse(errorsObject)

          if (errorsObject && errors) {
            this.validationErrors = {
              ...this.validationErrors,
              ...errors,
            }
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss">
.create-new-scenario {
  max-width: 560px;
  padding: 8px;

  &__header {
    justify-content: center;
  }

  &__heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;
  }

  &__footer {
    justify-content: center;
  }

  &__button {
    min-width: 140px;
  }
}
</style>
