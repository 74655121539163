<template>
    <CCard class="templates-list">
        <teleport v-if="mountedComponent" to="#templates-header">
            <h1 class="templates-list__heading templates-list__heading--1">Templates</h1>
        </teleport>

        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem active>Templates</CBreadcrumbItem>
        </teleport>

        <CCardBody class="templates-list__body">
            <div class="templates-list__row templates-list__row--flex-start">
                <GCFilter ref="filterRef" component="Templates" class="templates-list__filter"
                          @search="(value) => fetchDataByFilter(value)"/>

                <BulkAction :items="selectedItems" :type="tableType" :loading="loading"
                            :active-scenario="activeScenario" class="templates-list__bulk-action"
                            @move-or-copy="moveOrCopyHandler" @restore-templates="restoreTemplateHandler"
                            @delete="deleteHandler" @archive="archiveHandler" @shortcut="addShortcutToHandler"
                            @download="downloadHandler"/>
            </div>

            <div class="templates-list__row">
                <div class="templates-list__col templates-list__col--left">
                    <div class="templates-list__section">
                        <div class="templates-list__header">
                            <h3 class="templates-list__heading templates-list__heading--3">Directories</h3>
                        </div>

                        <Directories :active-directory="activeDirectory" :directories="directories"
                                     @change="setActiveDirectory"/>
                    </div>

                    <div class="templates-list__section">
                        <div class="templates-list__header">
                            <h3 class="templates-list__heading templates-list__heading--3">Scenarios</h3>

                            <CButton class="templates-list__button" color="link" size="sm"
                                     @click="openPopup('createNewScenarioPopup')">
                                <CIcon name="cilPlus" size="sm"/>
                            </CButton>
                        </div>

                        <Scenarios class="templates-list__scenarios" :active-scenario="activeScenario"
                                   :scenarios="scenarios" @change="setActiveScenario" @create-package="createPackage"
                                   @rename="renameHandler" @delete="deleteHandler" @move-or-copy="moveOrCopyHandler"
                                   @download="downloadHandler" @duplicateScenario="duplicateHandler"/>
                    </div>

                    <div class="templates-list__section">
                        <div class="templates-list__header">
                            <h3 class="templates-list__heading templates-list__heading--3">Tags</h3>
                        </div>

                        <Labels :labels="labels" :selected-labels="selectedLabels" @updateData="fetchAllLabels"
                                @change="setSelectedLabels"/>
                    </div>
                </div>

                <div class="templates-list__col templates-list__col--middle">
                    <div class="templates-list__table-wrapper">
                        <Table v-model:selected-items="selectedItems" class="templates-list__table" :files="files"
                               :type="tableType" :loading="loading" :active-scenario="activeScenario" :sort="sort"
                               @sort="sortingTableColumn" @eventDrag="eventDrag" @eventDrop="eventDrop"
                               @eventDropUpload="eventDropUpload" @addShortcutTo="addShortcutToHandler"
                               @addToQuickAccess="addToQuickAccessHandler" @viewDetails="viewDetailsHandler"
                               @archive="archiveHandler" @delete="deleteHandler" @download="downloadHandler"
                               @moveOrCopy="moveOrCopyHandler" @setPrimaryTemplate="setPrimaryTemplateHandler"
                               @restoreTemplate="restoreTemplateHandler" @setActiveScenario="setActiveScenario"
                               @createTemplate="createTaskContract"
                               @openAddTemplatesPopup="(data) => openPopup('addTemplatesPopup', data)"
                               @createPackage="createPackage(activeScenario?.scenarioId || undefined)"/>

                        <div v-if="loading" class="templates-list__loader">
                            <CSpinner color="primary"/>
                        </div>
                    </div>
                </div>

                <div v-if="templateDetails" class="templates-list__col templates-list__col--right">
                    <ViewDetails :template-name="templateDetails.name" :template-type="templateDetails.type"
                                 :template-id="templateDetails.id" :usage-list="templateDetails.usage"
                                 :size="templateDetails.size" :created-by="templateDetails.created_by"
                                 :creation-date="templateDetails.created_at" :last-modified="templateDetails.updated_at"
                                 :loading="loadingTemplateDetails" @close="closeDetailsHandler"
                                 @setActiveScenario="setActiveScenario"/>
                </div>
            </div>

            <div v-if="loadingPage" class="templates-list__loading-page">
                <CSpinner color="primary" size="lg"/>
            </div>
        </CCardBody>

        <CreateNewScenario :visible="createNewScenarioPopup" @close="closePopup('createNewScenarioPopup')"
                           @updateData="updateData"/>
        <CreateNewPackage :visible="createNewPackagePopup" :data="popupAdditionalData"
                          @close="closePopup('createNewPackagePopup')" @updateData="updateData"/>
        <Rename :visible="renamePopup" :data="popupAdditionalData" @close="closePopup('renamePopup')"
                @updateData="updateData"/>
        <MovingPopup :visible="movingPopup" :data="popupAdditionalData" @close="closePopup('movingPopup')"
                     @updateData="updateData"/>
        <AddTemplatesPopup :visible="addTemplatesPopup" :data="popupAdditionalData"
                           @close="closePopup('addTemplatesPopup')" @updateData="updateData"
                           @createTemplate="createTaskContract"/>
        <AddLabelPopup :visible="addLabelPopup" :data="popupAdditionalData" @close="closePopup('addLabelPopup')"
                       @updateData="updateData(true)"/>

        <ConfirmDialog ref="confirmDelete"/>
        <ConfirmDialog ref="confirmSetAsPrimary"/>
    </CCard>

    <div class="wide-modal">
        <CModal :visible="createModal" @close="closeTypeModal">
            <CModalHeader></CModalHeader>

            <CModalBody>
                <h3 class="task-contract__title">Create a contract or task template</h3>
                <div class="task-contract__options">
                    <div class="task-contract__options-item">
                        <div class="task-contract__options-content">
                            <h5 class="task-contract__options-title">
                                <div class="form-check">
                                    <input v-model="type" value="contract" class="form-check-input" type="radio"
                                           name="flexRadioDefault"/>
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Create contract template
                                    </label>
                                </div>
                            </h5>
                            <p class="task-contract__options-text">
                                Upload your contract document. Only files in .doc or .docx formats allow the addition of
                                the merge fields. It is quite essential as the signature(s) merge fields are required
                                for contract.
                            </p>
                        </div>
                    </div>

                    <div class="task-contract__options-item">
                        <div class="task-contract__options-content">
                            <h5 class="task-contract__options-title">
                                <div class="form-check">
                                    <input v-model="type" value="task" class="form-check-input" type="radio"
                                           name="flexRadioDefault"/>
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Create task template
                                    </label>
                                </div>
                            </h5>
                            <p class="task-contract__options-text">
                                For the tasks requiring <strong>acknowledgment</strong> you have several options:
                            </p>
                            <ul class="task-contract__options-list">
                                <li>Upload doc or .docx files and add merge fields</li>
                                <li>Upload other formats, but merge fields are not supported (At the time)</li>
                            </ul>
                            <br/>
                            <p class="task-contract__options-text">
                                To <strong>request a document</strong>, you can attach a sample or simply require the
                                document you expect from someone.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="task-contract__control">
                    <CButton style="min-width: 200px" color="primary" variant="outline"
                             class="m-2 ml-0 d-block w-sm-100" @click="closeTypeModal">
                        Cancel
                    </CButton>

                    <CButton :disabled="!type" style="min-width: 200px" color="primary"
                             class="m-2 ml-0 d-block w-sm-100" @click="submitCreate">
                        Create Template
                    </CButton>
                </div>
            </CModalBody>
        </CModal>
    </div>
</template>

<script>
import CreateNewScenario from '@/views/Templates/Components/Popups/CreateNewScenario';
import CreateNewPackage from '@/views/Templates/Components/Popups/CreateNewPackage';
import Rename from '@/views/Templates/Components/Popups/Rename';
import ConfirmDialog from '@/components/Modals/ConfirmDialog';
import Directories from '@/views/Templates/List/parts/Directories';
import Scenarios from '@/views/Templates/List/parts/Scenarios';
import GCFilter from '@/components/GCFilter';
import MovingPopup from '@/views/Templates/Components/Popups/MovingPopup';
import Table from '@/views/Templates/Components/Table';
import {mapActions, mapGetters} from 'vuex';
import {isEqual} from 'lodash/lang';
import debounce from 'lodash/debounce';
import BulkAction from '@/views/Templates/Components/BulkAction';
import ViewDetails from '@/views/Templates/Components/ViewDetails';
import {serialize} from 'object-to-formdata';
import AddTemplatesPopup from '@/views/Templates/Components/Popups/AddTemplatesPopup';
import AddLabelPopup from '@/views/Templates/Components/Popups/AddLabelPopup';
import Labels from '@/views/Templates/List/parts/Labels';
import Gate from '@/services/Gate/Gate';
import {permissionError} from '@/services/Notify/Toasts';

export default {
    name: 'Index',
    components: {
        Labels,
        AddLabelPopup,
        AddTemplatesPopup,
        ViewDetails,
        BulkAction,
        Table,
        MovingPopup,
        ConfirmDialog,
        Rename,
        CreateNewPackage,
        CreateNewScenario,
        Scenarios,
        Directories,
        GCFilter,
    },
    inject: ['mountedComponent', 'toast', 'downloadToast'],
    data() {
        return {
            loading: false,
            loadingPage: false,

            createModal: false,

            bindingUploadFilesData: {},

            templateDetails: null,
            loadingTemplateDetails: false,

            createNewScenarioPopup: false,
            createNewPackagePopup: false,
            movingPopup: false,
            renamePopup: false,
            addTemplatesPopup: false,
            addLabelPopup: false,
            popupAdditionalData: {},

            activeDirectory: 'all',
            activeScenario: null,
            directories: [
                {label: 'All Files', value: 'all', icon: 'cilFolder'},
                {label: 'Archive', value: 'archive', icon: 'cilStorage'},
                {label: 'Quick Access', value: 'favorite', icon: 'cilStar'},
            ],
            labels: [],
            selectedLabels: [],

            sort: {},
            filterData: {},

            selectedItems: [],

            type: null,
            createManualPassed: null,
            createTemplateParams: null,
        };
    },
    computed: {
        ...mapGetters({
            scenarios: 'scenarios',
            files: 'files',
        }),

        tableType() {
            let type = '';

            if (this.activeDirectory) {
                type = this.activeDirectory;
            } else if (this.activeScenario) {
                type = this.activeScenario?.packageId ? 'package' : 'scenario';
            } else {
                type = 'filter';
            }

            return type;
        },

        topActions() {
            return [
                {
                    label: 'Create Task/Contract Template',
                    event: this.createTaskContract,
                    visible: true,
                },
                {
                    label: 'Create a Request Template',
                    event: this.createRequestTemplate,
                    visible: false,
                },
                {
                    label: 'Create Template With Attachment',
                    event: () => this.createRequestTemplate(1),
                    visible: false,
                },
                {
                    label: 'Create New Scenario',
                    event: this.createScenario,
                    visible: true,
                },
                {
                    label: 'Create New Package',
                    event: this.createPackage,
                    visible: true,
                }
            ];
        },
    },
    watch: {
        files() {
            this.selectedItems = [];
        },
        tableType(type) {
            if (type !== 'filter') {
                this.filterData = {};
            }
        },
        selectedLabels: debounce(async function () {
            this.loading = true;
            const requests = this.updateTableByType();
            await Promise.all(requests).finally(() => (this.loading = false));
        }, 500),
    },
    async mounted() {
        this.loadingPage = true;
        const requests = [this.fetchScenarios(), this.fetchTemplates(), this.fetchAllLabels()];
        await Promise.all(requests).finally(() => (this.loadingPage = false));

        this.$EventBus.emit(
            'updateTopActions',
            this.topActions.filter((el) => el.visible),
        );

        this.$http.onboarding.getActions().then((res) => {
            const action = 'create_template_page';
            const actionsList = res.data.data;
            const selectedAction = actionsList.find((actionObject) => actionObject.action === action);

            this.createManualPassed = selectedAction.checked;
        });
    },
    methods: {
        ...mapActions({
            getScenariosList: 'getScenariosList',
            getAllTemplates: 'getAllTemplates',
            getScenarioData: 'getScenarioData',
            getPackageData: 'getPackageData',
            getDataByFilter: 'getDataByFilter',
            deleteEntity: 'deleteEntity',
            addToFavorite: 'addToFavorite',
            setAsPrimary: 'setAsPrimary',
            getTemplateDetails: 'getTemplateDetails',
            restoreTemplates: 'restoreTemplates',
            copyEntity: 'copyEntity',
            moveEntity: 'moveEntity',
        }),

        closeTypeModal() {
            this.type = '';
            this.createModal = false;
        },

        submitCreate() {
            let query = {};

            if (this.createTemplateParams)
                query = {...this.createTemplateParams};

            this.$router.push({name: 'TemplateCreate', query: {type: this.type, ...query}});
        },

        async createTaskContract(params) {
            const check = await Gate.can('create', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }

            if (this.createManualPassed === true) {
                this.createTemplateParams = params;
                return (this.createModal = true);
            }

            if (this.createManualPassed === false) {
                this.$router.push({name: 'TemplateCreate', query: {...params}});
            }
        },

        async sortingTableColumn(sort) {
            this.sort = sort;
            this.loading = true;
            const requests = this.updateTableByType();
            await Promise.all(requests).finally(() => (this.loading = false));
        },
        fetchDataByFilter: debounce(function (data) {
            this.filterData = {};
            if (data.search?.length) this.filterData.search = data.search;
            if (data.type?.length) this.filterData.template_types = data.type;
            if (data.date_modified_from) this.filterData.date_from = data.date_modified_from;
            if (data.date_modified_to) this.filterData.date_to = data.date_modified_to;

            if (!Object.keys(this.filterData)?.length && this.tableType === 'filter') {
                this.setActiveDirectory('all');
            } else {
                this.loading = true;
                this.activeDirectory = null;
                this.activeScenario = null;
                return this.fetchFilter().finally(() => (this.loading = false));
            }
        }, 500),
        fetchFilter() {
            return this.getDataByFilter({
                params: {
                    sort_field: this.sort.column || 'name',
                    sort_direction: this.sort.state || 'asc',
                    per_page: -1,
                    page: 1,
                    labels: this.selectedLabels,
                    ...this.filterData,
                },
            });
        },
        fetchScenarios() {
            return this.getScenariosList({
                params: {
                    sort_field: 'name',
                    sort_direction: 'asc',
                },
            });
        },
        fetchTemplates({archive, favorite} = {}) {
            return this.getAllTemplates({
                params: {
                    sort_field: this.sort.column || 'name',
                    sort_direction: this.sort.state || 'asc',
                    per_page: -1,
                    page: 1,
                    labels: this.selectedLabels,
                    archive,
                    favorite,
                },
            });
        },
        fetchScenarioData(id) {
            return this.getScenarioData({
                params: {
                    sort_field: this.sort.column || 'name',
                    sort_direction: this.sort.state || 'asc',
                    collection_id: id,
                    labels: this.selectedLabels,
                },
            });
        },
        fetchPackageData(id) {
            return this.getPackageData({
                params: {
                    sort_field: this.sort.column || 'name',
                    sort_direction: this.sort.state || 'asc',
                    folder_id: id,
                    labels: this.selectedLabels,
                },
            });
        },
        fetchTemplateDetails(id) {
            this.templateDetails = {};
            this.loadingTemplateDetails = true;

            return this.getTemplateDetails({params: {template_id: id}})
                .then((response) => {
                    this.templateDetails = response;
                })
                .finally(() => (this.loadingTemplateDetails = false));
        },
        fetchAllLabels() {
            return this.$http.library.getLabels().then((response) => {
                const data =
                    response.data.data?.map((label) => ({
                        value: label.id,
                        label: label.name,
                    })) || [];

                this.labels = data;
            });
        },

        updateTableByType() {
            const requests = [];

            switch (this.tableType) {
                case 'all':
                    requests.push(this.fetchTemplates());
                    break;
                case 'archive':
                    requests.push(this.fetchTemplates({archive: 1}));
                    break;
                case 'favorite':
                    requests.push(this.fetchTemplates({favorite: 1}));
                    break;
                case 'scenario':
                    requests.push(
                        this.fetchScenarioData(this.activeScenario?.scenarioId).catch(() => {
                            this.setActiveDirectory('all');
                        }),
                    );
                    break;
                case 'package':
                    requests.push(
                        this.fetchPackageData(this.activeScenario?.packageId).catch(() => {
                            this.setActiveDirectory('all');
                        }),
                    );
                    break;
                case 'filter':
                    requests.push(this.fetchFilter());
                    break;
            }

            return requests;
        },

        async updateData(updateLabels = false) {
            this.loadingPage = true;

            const requests = this.updateTableByType();

            if (updateLabels) {
                requests.push(this.fetchAllLabels());
            } else {
                this.fetchScenarios();
            }

            await Promise.all(requests).finally(() => (this.loadingPage = false));
        },

        //Setting
        setActiveDirectory(value) {
            if (value === this.activeDirectory) return;

            this.activeDirectory = value;
            this.activeScenario = null;
            this.sort = {};
            this.$refs.filterRef.handleRemove([], false);

            const data = {};

            switch (value) {
                case 'archive':
                    data.archive = 1;
                    break;
                case 'favorite':
                    data.favorite = 1;
                    break;
            }

            this.loading = true;
            this.fetchTemplates(data).finally(() => (this.loading = false));
        },
        setActiveScenario({scenarioId, packageId}) {
            const previousActiveScenario = this.activeScenario;
            this.activeScenario = {scenarioId, packageId};
            this.activeDirectory = null;
            this.sort = {};
            this.$refs.filterRef.handleRemove([], false);

            if (packageId && previousActiveScenario?.packageId !== packageId) {
                this.loading = true;
                this.fetchPackageData(packageId).finally(() => (this.loading = false));
            } else if (
                (scenarioId && previousActiveScenario?.scenarioId !== scenarioId) ||
                !isEqual(previousActiveScenario, this.activeScenario)
            ) {
                this.loading = true;
                this.fetchScenarioData(scenarioId).finally(() => (this.loading = false));
            }
        },
        async openPopup(popupName, data) {
            const check = await Gate.can('create', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            if (data) {
                this.popupAdditionalData = data;
            }
            this[popupName] = true;
        },
        closePopup(popupName) {
            this[popupName] = false;
            this.popupAdditionalData = {};
        },
        setSelectedLabels(labels) {
            this.selectedLabels = labels;
        },
        eventDrag({event, item}) {
            event.dataTransfer.setData('text/plain', JSON.stringify(item));
        },
        async eventDropUpload({event, item}) {
            const check = await Gate.can('create', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            const files = event.dataTransfer.files;

            if (!files?.length && !this.activeScenario.packageId) return;

            this.onFileUpload(files, this.activeScenario.packageId, item.sectionId);
        },
        async eventDrop({event, item}) {
            const check = await Gate.can('edit', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            if (!this.activeScenario.packageId) return;

            const value = JSON.parse(event.dataTransfer.getData('text/plain'));
            const currentPlace = +value.selectable_id.split(',')[0].split('.')[1];

            if (currentPlace === item.sectionId) return;

            const _data = {
                from: 'package',
                from_id: this.activeScenario.packageId,
                to: 'package',
                to_id: this.activeScenario.packageId,
                templates: [
                    {
                        template_id: value.id,
                        type: item.sectionId,
                        type_from: currentPlace,
                    },
                ],
            };

            this.loading = true;
            this.moveEntity(_data)
                .then((response) => {
                    this.toast('success', response);
                    this.updateData();
                })
                .catch((error) => {
                    this.toast('error', error.response.data.message);
                })
                .finally(() => (this.loading = false));
        },
        async createScenario() {
            await Gate.can('create', 'template').then((res) => {
                res ? this.openPopup('createNewScenarioPopup') : this.$notify(permissionError);
            });
        },
        async createPackage(scenarioId) {
            const check = await Gate.can('create', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            if (scenarioId) {
                this.openPopup('createNewPackagePopup', {
                    selectedScenario: scenarioId,
                });
            } else {
                this.openPopup('createNewPackagePopup', {list: this.scenarios});
            }
        },
        async renameHandler(data) {
            await Gate.can('edit', 'template').then((res) => {
                res ? this.openPopup('renamePopup', data) : this.$notify(permissionError);
            });
        },
        async moveOrCopyHandler(data) {
            const check = await Gate.can('create', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            this.openPopup('movingPopup', {
                nested: data.item_type === 'template',
                is_shortcut: false,
                ...data,
            });
        },
        async addShortcutToHandler(data) {
            const check = await Gate.can('edit', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            this.openPopup('movingPopup', {
                name: data?.name,
                items: data.items,
                nested: true,
                is_shortcut: true,
            });
        },
        async addToQuickAccessHandler(data) {
            const check = await Gate.can('edit', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            this.loading = true;

            this.addToFavorite({
                item_id: data.id,
                type: 'template',
            })
                .then(async () => {
                    const requests = this.updateTableByType();
                    await Promise.all(requests);
                })
                .finally(() => (this.loading = false));
        },
        async viewDetailsHandler(id) {
            if (!id) return;

            await this.fetchTemplateDetails(id);
        },
        closeDetailsHandler() {
            this.templateDetails = null;
        },
        async duplicateHandler(data) {
            const check = await Gate.can('edit', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            const _data = {};

            switch (data.from) {
                case 'template':
                    _data.templates = [{template_id: data.id}];
                    break;
                case 'scenario':
                    _data.from = 'scenario';
                    _data.from_id = data.id;
                    break;
            }

            this.copyEntity(_data)
                .then((response) => {
                    this.toast('success', response);
                    this.updateData();
                })
                .catch((error) => {
                    this.toast('success', error.response.data.message);
                });
        },
        archiveHandler(templates, name) {
            this.deleteHandler({templates}, name);
        },
        async deleteHandler(data, name) {
            const check = await Gate.can('delete', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            let _data = null;
            let popupData = null;

            if (this.tableType === 'package') {
                _data = {
                    ...data,
                    templates: (data.templates || []).map((template) => {
                        const [template_id, type] = String(template).split('.');
                        return {
                            template_id: +template_id,
                            type: +type,
                        };
                    }),
                };
            } else if (data.templates) {
                _data = {
                    ...data,
                    templates: data.templates.map((template_id) => ({
                        template_id,
                    })),
                };
            } else {
                _data = data;
            }

            if (_data?.from || _data?.scenarios) {
                popupData = {
                    text: `Are you sure you want to delete ${name || 'files'}?`,
                    info: 'Original templates will still be available in the Library',
                    confirmText: 'Delete',
                };
            } else {
                popupData = {
                    text: `Are you sure you want to archive ${name || 'templates'}?`,
                    confirmText: 'Archive',
                };
            }

            if (popupData) {
                this.$refs.confirmDelete
                    .confirm({
                        text: popupData?.text,
                        info: popupData?.info || '',
                        cancelText: 'Cancel',
                        confirmText: popupData?.confirmText,
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            const formData = serialize(_data, {indices: true});
                            const localData = Object.fromEntries(formData);

                            this.deleteEntity(localData)
                                .then((response) => {
                                    this.toast('success', response);
                                    this.updateData();
                                })
                                .catch((error) => {
                                    if (error.response.status === 400 && error.response?.data?.length) {
                                        let item = error.response?.data[0];
                                        this.$notify({
                                            type: 'info',
                                            content: `Impossible to delete template "${item.template.name}". It is used for recurrence settings in scope "${item.scope.name}".`,
                                        });
                                        return;
                                    }

                                    const items =
                                        error.response.data.errors?.projects ||
                                        error.response.data.errors?.scopes ||
                                        [];
                                    const joinNames = items?.map((el) => el.name).join(', ');
                                    const message = `${error.response.data.errors.message} ${joinNames}`;

                                    this.toast('error', message);
                                });
                        }
                    });
            }
        },
        downloadHandler(data) {
            const {type, id} = data;
            let _data = {};

            if (type && id) {
                switch (type) {
                    case 'scenario':
                        _data.scenario_id = id;
                        _data.action = 'library_scenario';
                        break;
                    case 'package':
                        _data.package_id = id;
                        _data.action = 'library_package';
                        break;
                    case 'template':
                        _data.template_id = id;
                        _data.action = 'library_template';
                        break;
                }
            } else {
                _data = {
                    action: 'library_items',
                    ...data,
                };
            }

            this.$http.common
                .downloadInitJob(_data)
                .then(({data}) => {
                    // this.toast('success', data.message)
                    this.downloadToast.open(data.download_token, data.preview_title);
                })
                .catch((error) => this.toast('error', error.response.data.message));
        },
        async setPrimaryTemplateHandler(data) {
            const check = await Gate.can('edit', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            this.loading = true;
            this.setAsPrimary(data)
                .then((response) => {
                    this.toast('success', response);
                    this.updateData();
                })
                .catch((error) => {
                    this.toast('error', error.response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async restoreTemplateHandler(templates) {
            const check = await Gate.can('edit', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            this.restoreTemplates({templates}).then((response) => {
                this.toast('success', response.data.message);
                this.updateData();
            });
        },

        async createRequestTemplate(manualSignature = undefined) {
            const check = await Gate.can('create', 'template');
            if (!check) {
                this.$notify(permissionError);
                return;
            }
            let query = {};

            if (manualSignature) {
                query.manualSignature = 1;
            }
            this.$router.push({
                name: 'TemplateCreate',
                query,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.wide-modal {
    & .modal-dialog {
        & .modal-header {
            margin-top: 20px;
        }

        .task-contract {
            padding-bottom: 20px;

            &__control {
                display: flex;
                justify-content: center;
                margin-top: 49px;
            }

            &__heading {
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: 0.2px;
                color: #1c262f;
            }

            &__checkbox {
                margin-top: 59px;
            }

            &__title {
                text-align: center;
                font-size: 24px;
                font-weight: 600;
                margin: 5px 0 24px 0;
            }

            &__image {
                height: 142px;
                display: block;
                margin: 0 auto;
            }

            &__divider {
                width: 88px;
                height: 2px;
                display: block;
                border: none;
                background: #0895d1;
                margin: 0 auto;
            }

            &__options {
                margin-top: 60px;
                display: flex;
                flex-direction: column;
                gap: 40px;

                &-content {
                }

                &-item {
                    padding-right: 20px;
                }

                &-list {
                    padding: 0 27px;
                    margin: 0;

                    & li {
                        font-size: 14px;
                        font-weight: 400;
                        color: #677a89;
                    }
                }

                &-title {
                    font-size: 18px;
                    color: #1c262f;
                    font-weight: 600;
                    margin-bottom: 15px;
                    display: flex;
                    align-items: center;
                }

                &-text {
                    font-size: 14px;
                    font-weight: 400;
                    color: #677a89;
                    margin: 0;
                    padding-left: 27px;
                }

                & strong {
                    font-weight: 700;
                    font-size: 14px;
                    color: #677a89;
                }

                &-submit {
                    margin-top: 44px;
                    min-height: 40px;
                    padding: 0 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: none;
                    border: 1px solid #1c262f;
                    border-radius: 8px;
                }
            }
        }
    }

    & .modal-body {
        padding: 0 40px 40px 40px;
    }
}

.templates-list {
    &__row {
        display: flex;

        &:first-child {
            margin-bottom: 16px;
        }

        &--flex-start {
            align-items: flex-start;
        }
    }

    &__filter {
        max-width: 718px;
        width: 100%;
    }

    &__body {
        position: relative;
    }

    &__upload-files {
        display: none;
    }

    &__loading-page {
        z-index: 999;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(250, 250, 250, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__bulk-action {
        margin-left: 20px;
    }

    &__col {
        &--left {
            flex: 0 0 250px;
            margin-right: 20px;
            margin-left: -16px;
        }

        &--middle {
            flex: 1 1 auto;
            overflow-x: auto;
        }

        &--right {
            flex: 0 0 230px;
            margin-left: 20px;
        }
    }

    &__table-wrapper {
        position: relative;
    }

    &__loader {
        position: absolute;
        padding-top: 44px;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(250, 250, 250, 0.7);
        z-index: 2;
    }

    &__section {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    &__heading {
        &--1 {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.2px;
            color: #1c262f;
        }

        &--3 {
            padding-left: 16px;
            font-size: 18px;
            font-weight: 700;
            color: #303c54;
            margin: 0;
        }
    }

    &__button {
        padding: 1px 7px;
        color: #1c262f;
    }

    &__scenarios {
        max-height: 30vh;
        overflow-y: auto;
    }
}

.task-contract {
    max-width: 560px;
    padding: 8px;

    &__header {
        position: relative;

        .btn-close {
            position: absolute;
            top: 21px;
            right: 4px;
        }
    }

    &__body {
        margin-top: 27px;
    }

    &__subtitle {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.002em;
        color: #1c262f;
        margin-bottom: 24px;
    }

    &__text {
        margin-top: 52px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.003em;
        color: #677a89;
    }

    &__title {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 32px;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #1c262f;

        &::after {
            position: absolute;
            content: '';
            height: 2px;
            width: 120px;
            bottom: -20px;
            left: calc(50% - 60px);
            background-color: #0081c2;
        }
    }

    &__footer {
        margin-top: 23px;
        justify-content: center;
    }
}
</style>
