<template>
  <div class="add-new-item">
    <div v-if="show" class="add-new-item__field">
      <CIcon v-if="icon" :name="icon" class="add-new-item__icon" />

      <CFormInput
        v-model="name"
        type="text"
        size="sm"
        :placeholder="placeholder"
        class="add-new-item__input"
        :invalid="!!validationErrors['name']"
        :disabled="pending"
        @blur="validateField"
      />

      <CButton
        class="add-new-item__icon-button"
        size="sm"
        color="primary"
        :disabled="!name"
        @click="create"
      >
        <CIcon size="sm" name="cilCheckAlt" />
      </CButton>

      <CButton
        class="add-new-item__icon-button"
        size="sm"
        color="primary"
        variant="outline"
        @click="hideField"
      >
        <CIcon size="sm" name="cilX" />
      </CButton>
    </div>

    <div v-else class="add-new-item__action">
      <CButton
        size="sm"
        color="link"
        class="add-new-item__button"
        :disabled="pending || show"
        @click="showField"
      >
        <CIcon
          name="cilPlus"
          class="add-new-item__icon add-new-item__icon--x"
        />

        <span class="add-new-item__text"> {{ buttonText }} </span>
      </CButton>
    </div>

    <div v-if="validationErrors['name']" class="add-new-item__field-error">
      <span class="add-new-item__error-text">
        {{ validationErrors['name'] }}
      </span>
    </div>
  </div>
</template>

<script>
import rules from '@/utils/validator/rules'
import validator from '@/utils/validator'

export default {
  name: 'AddNewItem',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    pending: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['create'],
  data() {
    return {
      name: '',
      show: false,

      validator: {},
      validationErrors: {},
    }
  },
  watch: {
    errors(newValue) {
      this.validationErrors = {
        ...this.validationErrors,
        ...newValue,
      }
    },
    buttonText() {
      this.hideField()
    },
  },
  methods: {
    setRules() {
      this.validator = validator({
        name: [rules.required, rules.strMax(255)],
      })
    },
    validateField() {
      this.validationErrors.name = this.validator.validate('name', this.name)
    },

    showField() {
      this.setRules()
      this.show = true
    },
    hideField() {
      this.name = ''
      this.show = false
      this.validator = {}
      this.validationErrors = {}
    },
    create() {
      this.setRules()
      const validationResult = this.validator.validateAll({ name: this.name })
      this.validationErrors = this.$deepClone(validationResult.validationErrors)

      if (validationResult.hasErrors) return

      this.$emit('create', this.name)
      this.hideField()
    },
  },
}
</script>

<style lang="scss" scoped>
.add-new-item {
  &__field {
    display: flex;
    align-items: center;
    padding: 0 16px;
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 8px;

    &--x {
      color: #677a89;
    }
  }

  &__input {
    margin-right: 10px;
  }

  &__field-error {
    padding: 0 40px;
  }

  &__button {
    padding: 4px 14px;
  }

  &__icon-button {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  &__text {
    font-size: 14px;
    color: #0068ad;
    text-decoration: underline;
  }

  &__error-text {
    font-size: 12px;
    color: #db000b;
  }
}
</style>
