<template>
  <div class="labels">
    <p v-if="!labels?.length" class="labels__empty">
      You do not have any bookmarks added yet
    </p>
    <template v-else>
      <CButton
        v-for="label in labels"
        :key="label.value"
        size="sm"
        color="link"
        class="labels__item"
        :class="{
          'labels__item--selected': selectedLabels.includes(label.value),
        }"
        @click="selectLabel(label)"
      >
        <Popper :content="label.label">
          <span class="labels__text">{{ label.label }}</span>
        </Popper>

        <CIcon
          class="labels__icon"
          size="sm"
          name="cilXCircle"
          @click.stop="deleteLabel(label)"
        />
      </CButton>
    </template>

    <ConfirmDialog ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import Gate from '@/services/Gate/Gate'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'Labels',
  components: { ConfirmDialog },
  inject: ['toast'],
  props: {
    selectedLabels: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['updateData', 'change'],
  methods: {
    selectLabel(label) {
      const labels = this.selectedLabels.includes(label.value)
        ? this.selectedLabels.filter((el) => el !== label.value)
        : [label.value, ...this.selectedLabels]

      this.$emit('change', labels)
    },
    rmLabelFromSelected(label) {
      const labels = this.selectedLabels.includes(label.value)
        ? this.selectedLabels.filter((el) => el !== label.value)
        : [...this.selectedLabels]

      this.$emit('change', labels)
    },
    async deleteLabel(label) {
      const check = await Gate.can('edit', 'template')
      if (!check) {
        this.$notify(permissionError)
        return false
      }
      this.$refs.confirmDelete
        .confirm({
          text: `Are you sure you want to delete the tag "${label.label}" from the system?`,
          cancelText: 'Cancel',
          confirmText: 'Delete',
          reverse: true,
        })
        .then((response) => {
          if (response) {
            this.$http.library
              .removeLabels({
                params: {
                  ids: [label.value],
                },
              })
              .then((response) => {
                this.toast('success', response.data.message)
                this.rmLabelFromSelected(label)
                this.$emit('updateData')
              })
              .catch((error) => {
                this.toast('error', error.response.data.message)
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.labels {
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  max-height: 200px;
  overflow-y: auto;

  &__empty {
    font-size: 14px;
    padding: 5px;
    color: rgba(48, 60, 84, 0.7);
  }

  &__item {
    background-color: #eeeeef;
    text-decoration: none;
    color: #303c54;
    margin: 5px;
    display: flex;
    align-items: center;
    transition: none;

    &:hover {
      background-color: rgba(238, 238, 239, 0.7);
    }

    &--selected {
      color: #eeeeef;
      background-color: #303c54;

      &:hover {
        background-color: rgba(48, 60, 84, 0.7);
      }
    }
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    width: 100%;
  }

  &__icon {
    flex-shrink: 0;
    margin-left: 7px;
  }
}
</style>
