<template>
  <CModal
    content-class-name="add-label-popup"
    size="lg"
    backdrop="static"
    :visible="visible"
    @close="closePopup"
  >
    <CModalHeader class="add-label-popup__header" :close-button="false">
      <CModalTitle class="add-label-popup__heading">Add tag</CModalTitle>
    </CModalHeader>

    <CModalBody class="add-label-popup__body">
      <Multiselect
        v-model="labels"
        mode="tags"
        placeholder="Type or select a tag"
        :loading="loading"
        :disabled="loading"
        :close-on-select="false"
        :searchable="true"
        :create-option="true"
        :options="options"
      />
    </CModalBody>

    <CModalFooter class="add-label-popup__footer">
      <CButton
        class="add-label-popup__button"
        variant="outline"
        color="primary"
        :disabled="loading"
        @click="closePopup"
      >
        Cancel
      </CButton>
      <CButton
        class="add-label-popup__button"
        color="primary"
        :disabled="loading"
        @click="addLabels"
      >
        Save
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
import Multiselect from '@vueform/multiselect'
export default {
  name: 'AddLabelPopup',
  components: { Multiselect },
  inject: ['toast'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['close', 'updateData'],
  data() {
    return {
      labels: [],
      options: [],
      loading: false,
    }
  },
  watch: {
    visible(visible) {
      if (visible) {
        this.options = this.data.options.map(option => option.label.length < 30 ? option.label : option.label.slice(0, 50) + '...')
        this.labels = this.data.labels?.map((el) => el.value) || []
      } else {
        this.labels = []
        this.options = []
        this.loading = false
      }
    },
  },
  methods: {
    closePopup() {
      this.$emit('close')
    },
    addLabels() {
      this.loading = true
      this.$http.library
        .addLabel(this.data.template_id, { labels: this.labels })
        .then((response) => {
          this.toast('success', response.data.message)
          this.closePopup()
          this.$emit('updateData')
        })
        .catch((error) => {
          this.toast('error', error.response.data.message)
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss">
.add-label-popup {
  max-width: 560px;
  padding: 8px;

  &__header {
    justify-content: center;
  }

  &__heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;
  }

  &__footer {
    justify-content: center;
  }

  &__button {
    min-width: 140px;
  }
}
</style>
