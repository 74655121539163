<template>
  <ul class="selecting-list">
    <template v-if="list.length">
      <li
        v-for="item in list"
        :key="item.id"
        class="selecting-list__item"
        :class="{
          'selecting-list__item--active': localValue === item.id,
        }"
      >
        <button
          class="selecting-list__button"
          @click="localValue = item.id"
          @dblclick="actionHandler(item)"
        >
          <CIcon
            v-if="listIcon"
            :name="listIcon"
            class="selecting-list__icon"
          />

          <span class="selecting-list__text"> {{ item.name }}</span>

          <button
            v-if="actionIcon"
            class="selecting-list__button selecting-list__button--action"
            @click.stop="actionHandler(item)"
          >
            <CIcon :name="actionIcon" />
          </button>
        </button>
      </li>
    </template>

    <li v-else class="selecting-list__item selecting-list__item--empty">
      <slot name="empty_list"> The list is empty </slot>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SelectingList',
  props: {
    modelValue: {
      type: Number,
      default: undefined,
    },
    list: {
      type: Array,
      default: () => [],
    },
    listIcon: {
      type: String,
      default: '',
    },
    actionIcon: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'action'],
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(id) {
        this.updateModelValueHandler(id)
      },
    },
  },
  methods: {
    updateModelValueHandler(id) {
      this.$emit('update:modelValue', id)
    },
    actionHandler(item) {
      this.$emit('action', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.selecting-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    display: flex;
    align-items: center;

    &:hover {
      background-color: rgba(238, 238, 239, 0.7);
    }

    &--active {
      background-color: #eeeeef;
    }

    &--empty {
      &:hover {
        background-color: transparent;
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 8px;
  }

  &__button {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 6px 16px;
    display: flex;
    align-items: center;

    &--action {
      margin-left: auto;
      width: auto;
    }
  }
}
</style>
